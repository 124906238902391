<template>
  <section class="banner bg-soft-primary flex" id="banner">
    <free-trial v-if="trial" @modal="modal" />

    <!-- <main-header class="fixed w-full" @modal="modal" /> -->
    <div class="container mx-auto flex justify-between items-center flex-wrap">
      <div
        class="info p-5 xl:px-11 mb-11 w-full"
        data-aos="zoom-in"
        data-aos-duration="1500"
        :class="items.image ? ' lg:w-1/2' : ''"
      >
        <h1 class="text-3xl text-primary">
          {{ items.title }}
        </h1>
        <p>
          {{ items.description }}
        </p>

        <div>
          <button class="bg-primary mt-4 rounded px-4 py-2 text-white">
            {{ $t('misc.free trial') }}
          </button>
        </div>
      </div>
      <div
        v-if="items.image"
        class="image p-5 w-full lg:w-1/2"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <img :src="items.image" class="w-full" alt="rwad-header" />
      </div>
    </div>
  </section>
</template>

<script>
import FreeTrial from '@/components/FreeTrial.vue'
// import MainHeader from './MainHeader.vue'
export default {
  props: ['items'],
  components: { FreeTrial },
  data() {
    return {
      trial: false,
    }
  },

  methods: {
    modal() {
      this.trial = !this.trial
    },
  },
}
</script>

<style scoped lang="scss">
.banner {
  min-height: 100vh;
  // background-image: url('@/assets/header-background.png');
  // background-size: cover;
  // background-position: center center;
  .container {
    .info {
      text-align: center;
      @media (min-width: 768px) {
        text-align: start;
      }
      h1 {
        margin-bottom: 24px;
        line-height: 1.2;
        color: #2b2b4f;
        font-weight: 700;
        font-size: 48px;
        // max-width: 400px;
      }
      p {
        color: #53575a;
        // max-width: 400px;
        font-size: 18px;

        // font: 400 1rem/1.625rem 'Open Sans', sans-serif;
      }
      @media (max-width: 992px) {
        padding-top: 150px;
        h1 {
          font-size: 40px;
          max-width: 100%;
        }
        p {
          max-width: 100%;
        }
      }
    }

    .image {
      img {
        // animation: bunce 5s linear infinite;
      }
    }
  }
}

@keyframes bunce {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
