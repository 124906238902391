<template>
  <section class="flex items-center">
    <div
      class="container p-5 mx-auto flex justify-between flex-wrap items-center"
    >
      <div class="w-full md:w-3/4">
        <h2 class="text-2xl font-bold text-white">
          واجهة سهلة الاستخدام بميزات متعددة
        </h2>
        <p class="text-md mt-8 text-white">
          لا حاجة لامتلاك معرفة عميقة في طرق المحاسبة أو نظم تخطيط الموارد
          المؤسسية لإتقان العمل على دفترة.
        </p>
      </div>
      <div class="w-full md:w-1/4">
        <a
          href="/register"
          class="bg-secondary px-5 py-4 rounded text-white font-bold"
        >
          إبدا الاستخدام مجانا
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.container {
  text-align: center;
  border-radius: 30px;
  background: url('@/assets/2.jpg') no-repeat fixed;
  background-size: cover;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  @media (min-width: 768px) {
    text-align: start;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  & > div {
    z-index: 10;
  }
}
</style>
