<template>
  <section class="services mt-11">
    <div class="container mx-auto">
      <div class="info mb-5">
        <h2 class="text-4xl font-bold text-primary mb-5">
          Services
        </h2>
        <p class="text-gray-500">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
          adipisci modi neque molestiae vel quidem pariatur enim. Officia dolore
          cupiditate voluptate, nemo, velit deleniti, sit voluptatibus ratione
          molestiae quidem consequuntur.
        </p>
      </div>
      <div class="mt-11 features">
        <div class="feature" v-for="item in items" :key="item.id">
          <div class="icon relative text-primary inline-block">
            <font-awesome-icon :icon="['fas', 'pencil']" />
          </div>
          <div class="p-2">
            <h5 class="text-black text-2xl mt-5">{{ item.title }}</h5>
            <p class="text-gray-600">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style lang="scss" scoped>
.services {
  .info {
    max-width: 80%;
    margin: 0 auto 50px;
    text-align: center;
  }
  .features {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .feature {
      width: 100%;
      padding: 30px;
      margin-bottom: 40px;
      &:hover {
        .icon {
          &::before {
            background: rgba(47, 85, 212, 0.1);
            animation: spinner 5s linear infinite !important;
          }
        }
      }
      .icon {
        svg {
          margin: 0 5px 13px;
          font-size: 24px;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 5px;
          left: 5px;
          width: 64px;
          height: 64px;
          border-radius: 6px;
          background: linear-gradient(
            45deg,
            transparent,
            rgba(47, 85, 212, 0.1)
          );
          h5 {
            line-height: 1.4;
          }
          p {
            line-height: 1.6;
          }
          transform: rotate(33.75deg);
        }
      }
      @media (min-width: 768px) {
        width: 33%;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(33.75deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-rtl {
  .features {
    .feature {
      .icon {
        &::before {
          left: unset;
          right: 5px;
        }
      }
    }
  }
}
</style>
