<template>
  <intro v-if="header" :items="header" />
  <Services v-if="services" :items="services" />
  <Subscripe />
  <Content v-if="sections" :items="sections" />
  <!-- <Subscripe /> -->
  <Dashboard v-if="dashboardInfo" :items="dashboardInfo" />
  <go-top />
</template>

<script>
import Intro from '@/components/Intro.vue'
import GoTop from '@/components/GoTop.vue'
import Services from '@/components/Services.vue'
import Content from '@/components/Content.vue'
import Subscripe from '@/components/Subscripe.vue'
import Dashboard from '../components/Dashboard.vue'

export default {
  components: {
    Intro,
    GoTop,
    Services,
    Content,
    Subscripe,
    Dashboard,
  },
  data() {
    return {
      services: null,
      dashboardInfo: null,
      sections: null,
      header: null,
    }
  },
  methods: {
    getSettings() {
      this.axios.get('lists').then((data) => {
        this.services = data.data.lists.services
        this.dashboardInfo = data.data.lists.dashboard
        this.header = data.data.lists.home
        this.sections = data.data.lists.sections
      })
    },
  },
  mounted() {
    this.getSettings()
  },
  computed: {
    locale() {
      return this.$store.getters.locale
    },
  },
}
</script>

<style></style>
