<template>
  <footer>
    <div></div>
  </footer>
</template>

<script>
export default {}
</script>

<style scoped>
footer {
  min-height: 50px;
  background-color: #202942;
}
</style>
