<template>
  <section class="relative dashboard">
    <div class="container mx-auto">
      <div class="image" v-if="items.image">
        <img class="mx-auto rounded-xl" :src="items.image" alt="Erp" />
      </div>
    </div>
    <div class="bgoverlay"></div>
  </section>
  <section class="face" v-for="item in items.sections" :key="item.id">
    <div class="container mx-auto">
      <div class="info flex justify-between flex-wrap">
        <div class="image w-full md:w-1/2" v-if="item.image">
          <img class="mx-auto" :src="item.image" alt="Erp" />
        </div>
        <div
          class="text w-full flex flex-col justify-center"
          :class="item.image ? ' md:w-1/2' : ''"
        >
          <h2 class="text-primary mb-11 font-bold text-2xl">
            {{ item.title }}
          </h2>
          <p class="text-lg text-gray-600">
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style scoped lang="scss">
section.dashboard {
  padding-top: 100px;
  @media (min-width: 768px) {
    min-height: calc(100vh - 80px);
  }
}
.image {
  padding-bottom: 40px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
  img {
    box-shadow: 0 15px 25px rgba(60, 72, 88, 0.15) !important;
    @media (min-width: 768px) and (max-width: 992px) {
      transform: translateY(80px);
    }
  }
}
.container {
  .info {
    padding: 50px 0;
  }
}
.bgoverlay {
  position: absolute;
  // display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-color: #2f55d4 !important;
  z-index: -1;
  @media (min-width: 768px) and (max-width: 992px) {
    height: 40%;
  }
}
section.face {
  &:nth-child(even) {
    background-color: rgba(47, 85, 212, 0.1) !important;
    .container {
      .info {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
